// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-all-songs-js": () => import("./../../../src/pages/all-songs.js" /* webpackChunkName: "component---src-pages-all-songs-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/CollectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-song-template-js": () => import("./../../../src/templates/SongTemplate.js" /* webpackChunkName: "component---src-templates-song-template-js" */)
}

